import React, { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";

export function useQuery() {
  const { search } = useLocation();
  return React.useMemo(() => new URLSearchParams(search), [search]);
}

const DEFAULT_OPTIONS_MUTATION_OBSERVABLE = {
    attributes: true,
    childList: true,
    subtree: true
};

export function useMutationObservable(ref, callback, options = DEFAULT_OPTIONS_MUTATION_OBSERVABLE) {
  
  useEffect(() => {
   
    if (ref?.current) {

      const observer = new MutationObserver(callback)
      observer.observe(ref.current, options)

      return () => { observer.disconnect() }

    }

   
  }, [callback, options]);


}

// Define the hook with 'query' parameter typed as a string
export const useMediaQuery = (query) => {
  const [matches, setMatches] = useState(false);

  useEffect(() => {
    const media = window.matchMedia(query);
    if (media.matches !== matches) {
      setMatches(media.matches);
    }

    // Define the listener as a separate function to avoid recreating it on each render
    const listener = () => setMatches(media.matches);

    // Use 'change' instead of 'resize' for better performance
    media.addEventListener("change", listener); 

    // Cleanup function to remove the event listener
    return () => media.removeEventListener("change", listener);

  }, [matches, query]); // Only recreate the listener when 'matches' or 'query' changes

  return matches;
};