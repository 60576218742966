import api from '../Api';
import { allowed_magic_numbers, getToken, magic_number_open_document, microsoftTypes, mime_type_pptx } from '../shared/utils';

class DocumentService {

    /**
     * Obtain single document.
     * @param {string} token 
     * @param {number} documentId 
     */
    static async fetch(token, documentId) {
        return api.get(`/v1/documents/${documentId}`, {headers: {'X-AUTH-TOKEN': token}});
    }

    /**
     * Obtain all documents.
     * @param {string} token 
     */
    static async fetchAll(token) {
        return api.get("/v1/documents", {headers: {'X-AUTH-TOKEN': token ? token : getToken()}});
    }

    /**
     * Delete multiple documents.
     * @param {string} token 
     * @param {array} documentIds 
     */
    static async delete(token, documentIds) {
        return api.delete(`/v1/documents`, {"data": {"ids": documentIds}, headers: {'X-AUTH-TOKEN': token}});
    }

    /**
     * Report a problem with a document.
     * @param {number} documentId 
     * @param {string} problem 
     */
    static async reportProblem(documentId, problem) {
        return api.post(`/v1/document/${documentId}/problem`, {"problem": problem}, {headers: {'X-AUTH-TOKEN': getToken()}});
    }

    static async uploadDocuments(files) {

      const apiHeaders = {headers: {'X-AUTH-TOKEN': getToken()}};

      const { data } = await api.get(`/v2/documents/upload-url?numberOfUploads=${files.length}`, apiHeaders);

      const urls = data.urls;
      const promises = [];
      const filesData = {};
      
      for(let i = 0;i < files.length;i++) {
        
        const url = urls[i];
        const file = files[i];
        filesData[url] = file;

        promises.push(fetch(url, {
          method: "PUT",
          body: file,
          headers: {
            "Content-Type": file.type,
            "Content-Length": file.size
           }
        }));

      }

      return Promise.allSettled(promises).then(results => {

        const items = [];
        results.filter(r => r.status === "fulfilled" && r.value.status === 200).forEach(r => {

          const strUrl = r.value.url;
          const file = filesData[strUrl];

          const url = new URL(strUrl);
          const key = url.pathname.substring(url.pathname.lastIndexOf("/") + 1);
          
          items.push({
            key: key,
            name: file.name,
            type: file.type,
            size: file.size
          });

        });


        return api.post("/v2/documents", {"items": items}, apiHeaders);

      });
    }

    static async validateFiles(files) {

      const invalid_docs = [];

      for (let i = 0;i < files.length;i++) {

        let magic_number;
        if (microsoftTypes.includes(files[i].type)) {
          magic_number = await getMagicNumber(files[i], 8);
        } else {
          magic_number = await getMagicNumber(files[i]);
        }

        if (!allowed_magic_numbers.includes(magic_number)) {

          // Sometimes PowerPoints arrives as "zip", we will allow it and validate on server side.
          if (mime_type_pptx === files[i].type) {
            magic_number = await getMagicNumber(files[i]);

            if (magic_number_open_document !== magic_number) {
              invalid_docs.push(files[i]);
            }

          } else {
            invalid_docs.push(files[i]);
          }

        }
      }

      return invalid_docs;
    }
}

async function getMagicNumber(file, signatureLength = 4) { 
  return file.slice(0, signatureLength)
              .arrayBuffer()
              .then((buffer) => Array.from(new Uint8Array(buffer)).map((byte) => byte.toString(16).padStart(2, '0')).join('').toUpperCase());
}

export default DocumentService;