import React from 'react';
import { FormattedMessage } from 'react-intl';
import { Link } from 'react-router-dom';
import { useSelector } from 'react-redux';

import './Error404.css';
import { LogoGreen } from '../Components/Icons';
import LanguageSelector from '../Components/Commons/LanguageSelector';

const Error404 = (props) => {

  if (window.FreshworksWidget) {
    window.FreshworksWidget('hide', 'launcher');
  }

  const support = useSelector(state => state.global.contactEmail);

  return (
    <div className='error-page-wrapper d-flex flex-column align-items-stretch h-100 p-2'>
      
      <div className='text-center error-page-logo mt-5 mx-auto'>
        <LogoGreen />
      </div>
    
      <div className="error-page mx-auto p-3">
        <div className='text-center'>
          
          <h1>404</h1>
          <h4><FormattedMessage id="error404.title" /></h4>
          <p>
            <FormattedMessage id="error404.text" values={{
              email: (
                <a href={`mailto:${support}`} target="blank" rel="noopener noreferrer">{support}</a>
              )
              }}
            />
          </p>
          <div>
            <Link to="/" className='btn btn-secondary'>
              <FormattedMessage id="error404.backToHome" />
            </Link>
          </div>
        </div>
      </div>

      <div className='text-center pb-3'>
        <LanguageSelector />
      </div>
    </div>
  );
};

export default Error404;
