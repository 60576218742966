import { EMAIL_PATTERN } from "./utils";

export const DEFAULT_MAX_LENGTH = 255;

export const isEmailList = (value) => {

  if (isEmpty(value)) {
    return undefined;
  }

  const emailList = value.split(",");

  if (emailList.length === 0) {
    return undefined;
  }

  for (var i = 0; i < emailList.length; i++) {
    if (!isEmpty(emailList[i]) && isEmail(emailList[i]) !== undefined) {
      return {
        id: "validations.fields.email.list"
      }
    }
  }

  return undefined;
}

export const isEmail = (value) => {

  const regex = EMAIL_PATTERN;

  if (regex.test(value)) {
    return undefined;
  }

  return {
    id: "validations.field.email"
  };
};

export const isEmpty = (obj) => {
  return obj === undefined || obj === null ||
    (typeof obj === "string" && obj.trim() === "") ||
    (Array.isArray(obj) && obj.length === 0);
}

export const required = (obj, formValues, allValues, fieldName) => {

  if (isEmpty(obj)) {

    return {
      id: "validations.field.required"
    };

  }

  return undefined;
};

export const requiredCheckbox = (obj, formValues, allValues, fieldName) => {

  if (obj === true || obj === "true") {
    return undefined;
  }

  return {
    id: "validations.field.required"
  };
};

export const requiredIf = (otherField) => (obj, formValues, allValues, fieldName) => {

  if (!isEmpty(formValues[otherField])) {
      return required(obj, formValues, allValues, fieldName)
  }

  return undefined;
}

export const maxLength = (max) => (value, formValues, allValues, fieldName) => {

  if (value && value.length > max) {

    return {
      id: "validations.field.maxLength",
      values: {
        length: max
      }
    };

  }

  return undefined;
}

export const minLength = (min) => (value, formValues, allValues, fieldName) => {

  if (value && value.length < min) {

    return {
      id: "validations.field.minLength",
      values: {
        length: min
      }
    };

  }

  return undefined;
}

export const defaultMaxLength = maxLength(DEFAULT_MAX_LENGTH);
