import React, { useEffect, useState } from "react";
import { useGlobal } from "../../contexts/GlobalContext";
import { useSelector } from "react-redux";
import { allNotNull, MAX_PROFILE_LEVEL } from "../../shared/utils";
import GlobalMessage from "./GlobalMessage";
import { FormattedMessage } from "react-intl";
import ProfileButton from "../Profile/ProfileButton";
import { Fade } from "reactstrap";
import LocalStorageSerivce from "../../Services/LocalStorageSerivce";

const DEFAULT_TIMEOUT = 300;
const backOnlineStorageKey = "backOnlineMessage"

const MessagesQueue = () => {

  const [messages, setMessages] = useState([]);
  const [currentMessage, setCurrentMessage] = useState(null);
  const [currentMessageKey, setCurrentMessageKey] = useState(null);
  const [isVisible, setIsVisible] = useState(false);

  // Context data
  const { globalLimitCounter } = useGlobal();
  const freeOffline = useSelector(state => state.system.system_free_offline);
  const backOnline = useSelector(state => state.system.system_back_online);
  const sections = useSelector(state => state.profile.list);

  const showNextMessage = () => {
    if (messages.length > 0) {
      const [nextMessage, ...remainingMessages] = messages;
      setIsVisible(false); // Fade out current message
      setTimeout(() => {
        setCurrentMessageKey(nextMessage.key);
        setCurrentMessage(nextMessage.component);
        setIsVisible(true); // Fade in new message
        setMessages(remainingMessages);
      }, DEFAULT_TIMEOUT); // Match timeout to Fade duration
    } else {
      setIsVisible(false);
      setTimeout(() => setCurrentMessage(null), DEFAULT_TIMEOUT);
    }
  };

  // Close handler for the current message
  const handleClose = () => {

    // If current message is back online we should hide it for a while
    if (backOnline === true && !LocalStorageSerivce.get(backOnlineStorageKey)) {
      LocalStorageSerivce.setWithExpiry(backOnlineStorageKey, "true",  24 * 60 * 60 * 1000) // 1 day
    }

    setIsVisible(false); // Trigger fade-out
    setTimeout(showNextMessage, 300); // Wait for fade-out to complete
  };

  // Show the first message when the queue updates
  useEffect(() => {
    if (!currentMessage && messages.length > 0) {
      showNextMessage();
    }
  }, [currentMessage, messages]);

  useEffect(() => {
    const dynamicMessages = [];

    if (allNotNull([backOnline, freeOffline, globalLimitCounter, sections])) {

      if (backOnline === true && !LocalStorageSerivce.get(backOnlineStorageKey)) {
        dynamicMessages.push({key: "msg_back_online", component: 
          <GlobalMessage type="accent" onClose={handleClose}>
            <FormattedMessage id="message.global.backOnline" />
          </GlobalMessage>
        });
      } else if (backOnline === false) {
        LocalStorageSerivce.remove(backOnlineStorageKey)
      }

      if (freeOffline) {
        dynamicMessages.push({key: "msg_free_offline", component:
          <GlobalMessage type="accent" onClose={handleClose}>
            <FormattedMessage id="message.global.freeOffline" />
          </GlobalMessage>
        });
      }

      if (sections.length !== 0) {
        dynamicMessages.push({key: "msg_fill_profile", component:
          <GlobalMessage type="accent" onClose={handleClose}>
            <div>
              <span className='mr-2'><FormattedMessage id="message.global.fillProfileForFreePages" /></span>
              <ProfileButton />
            </div>
          </GlobalMessage>
        });
      }

      if (globalLimitCounter.overLimit) {
        dynamicMessages.push({key: "msg_daily_limit_print", components:
          <GlobalMessage type="accent" onClose={handleClose}>
            <FormattedMessage id="message.global.dailyLimitPrintWithDeliveries" />
          </GlobalMessage>
        });
      }

    }

    setMessages(dynamicMessages);

  }, [backOnline, freeOffline, sections, globalLimitCounter]);

  useEffect(() => {

    if (currentMessageKey === "msg_fill_profile" && sections?.lenght === 0) {
      showNextMessage();
    }

  }, [currentMessageKey, sections]);

  return (
    <div>
      {currentMessage && (
        <Fade in={isVisible} className="message-wrapper">
          {currentMessage}
        </Fade>
      )}
    </div>
  );
}

export default MessagesQueue;