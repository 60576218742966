import React from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { Button, Form, FormGroup, Input, Label, Spinner } from 'reactstrap';
import { isEmailList, DEFAULT_MAX_LENGTH } from '../../shared/validations';
import { useForm } from 'react-hook-form';
import { InputErrors } from '../Commons/InputErrors';
import { UserService } from '../../Services';
import { useDispatch } from 'react-redux';
import { success as successNotification, error as errorNotification } from 'react-notification-system-redux';
import { globalErrorHandling } from '../../shared/utils';

const InviteFriendsByEmailForm = (props) => {

  const intl = useIntl();
  const dispatcher = useDispatch();

  const { register, clearErrors, handleSubmit, setError, formState: { submitting, errors } } = useForm({
        mode: "onSubmit"
  });

  const { ref: friendsRef, ...registerFriends } = register("friends", { required: true, maxLength: DEFAULT_MAX_LENGTH });

  const handleForm = (model) => {

    const emailListError = isEmailList(model.friends);

    if (emailListError) {
      setError(
        "friends",
        {
            "type": "manual",
            "message": intl.formatMessage({"id": emailListError.id})
        }
      );
      if (friendsRef.current) {
        friendsRef.current.focus();
      }
    } else{


      UserService.inviteFriends(model.friends).then(response => {

        dispatcher(successNotification({
          message: intl.formatMessage({ id: "inviteFriends.mailLink.message.success"}),
          position: 'tc',
          autoDismiss: 10,
        }));
  
      }).catch(error => {
  
        const errorObj = globalErrorHandling(error, "inviteFriends.mailLink.message.error");
  
        console.log(errorObj);
  
      }).finally(() => {
  
      });
    }

  }

  return (

    <Form onSubmit={handleSubmit(handleForm)}>
      <button type="submit" style={{display: "none"}}></button>
      
      <FormGroup>
        <div className="mb-1 mb-sm-1 mb-md-0">
            <Label className="font-weight-bold mb-0 mb-md-1" htmlFor="txtFriends">
              <FormattedMessage id="inviteFriends.mailLink" />
            </Label>
            <div className="mb-2">
              <FormattedMessage id="inviteFriends.mailLink.description" />
            </div>
        </div>

        <Input type='textarea' id="txtFriends" data-tes="form-invite-friends-by-email" innerRef={friendsRef} autoComplete='off'
          placeholder='example@example.com, example2@example.com' {...registerFriends} disabled={submitting} />
        <InputErrors fieldName="friends" errors={errors} />
      </FormGroup>

      <Button color='secondary' disabled={submitting} data-test="btn-invite-friends-by-email">
          <FormattedMessage id="inviteFriends.mailLink.button.send" />
          {submitting?<Spinner size="sm" className="ml-2"/>:null}
      </Button>
    </Form>

  );

}

export default InviteFriendsByEmailForm;
