import React from 'react';
import { connect } from "react-redux";
import { injectIntl } from 'react-intl';
import DocumentUpload from '../Components/Documents/DocumentUpload';
import {
  DocumentList,
} from '../Components/Documents';
import AddressModalForm from '../Components/Address/AddressModalForm';
import { SharedDocumentModal } from '../Components/SharedDocuments';
import { submit } from 'redux-form';
import {
  deliveryLockedToggle,
  fetchAddresses, addressFormToggle, saveAddress
} from '../store/actions';
import { Helmet } from "react-helmet";

import './DocumentPage.css'
import { PrivateTemplate } from '../Templates';
import CompetitionLeaderboardWrapper from '../Competition/Components/CompetitionLeaderbardWrapper';
import { Col, Row } from 'reactstrap';
import UnlimitedPrinting from '../Components/Messages/UnlimitedPrinting';
import PromoCodeBanner, { PromoCodeBanner2 } from '../Printing/Components/PromoCodeBanner';
import { Experiment, Variant } from '@marvelapp/react-ab-test';
import { EXPERIMENT_PROMO_CODE_CALL_TO_ACTION } from '../Experiments';

class DocumentPage extends React.Component {

  experimentBannerRef = React.createRef();
  experimentRightSectionRef = React.createRef();

  constructor(props) {
    super(props);

    this.handleOnSubmitAddress = this.handleOnSubmitAddress.bind(this);
  }

  static getDerivedStateFromError(error) {
    // Update state so the next render will show the fallback UI.
    return { hasError: true };
  }

  componentDidCatch(error, errorInfo) {
    console.log(error, errorInfo);
  }

  componentDidMount() {

    if (window.stripeApi === null || window.stripeApi === undefined) {
      // You can inject a script tag manually like this,
      // or you can use the 'async' attribute on the Stripe.js v3 <script> tag.
      const stripeJs = document.createElement('script');
      stripeJs.src = 'https://js.stripe.com/v3/';
      stripeJs.async = true;
      stripeJs.onload = () => {
        // The setTimeout lets us pretend that Stripe.js took a long time to load
        // Take it out of your production code!
        setTimeout(() => {
            window.stripeApi = window.Stripe(process.env.REACT_APP_STRIPE_API_KEY)
        }, 500);
      };
      
      document.body && document.body.appendChild(stripeJs);
    }
  }

  handleOnSubmitAddress(model) {

    this.props.saveAddress(model, true);
  }

  render() {

    return (
      <PrivateTemplate>
        <Helmet>
            <title>Zerocopy</title>
        </Helmet>

        <div className="container-fluid page-in">
          <div className='row'>
            <div className='col-xl-8 col-md-7 col-sm-12'>
              <Row className='mb-2'>
                <Col className='text-center'>
                  <UnlimitedPrinting />
                </Col>
              </Row>
              <Row className="mb-3">
                <Col>
                  <DocumentUpload />
                </Col>
              </Row>

              <Experiment ref={this.experimentBannerRef} name={EXPERIMENT_PROMO_CODE_CALL_TO_ACTION}>
                <Variant name='banner'>
                  <Row>
                    <Col className='text-center'>
                      <PromoCodeBanner onWin={() => this.experimentBannerRef.current.win()} />
                    </Col>
                  </Row>
                </Variant>
              </Experiment>
              
              <DocumentList />

              <AddressModalForm
                isOpen={this.props.openAddressForm}
                toggle={this.props.addressFormToggle}
                onSubmit={this.handleOnSubmitAddress}
                isNew={true}
              />

                <SharedDocumentModal />
            </div>
            <div className='col-xl-4 col-md-5 col-sm-12 pb-sm-3'>

              <Experiment ref={this.experimentRightSectionRef} name={EXPERIMENT_PROMO_CODE_CALL_TO_ACTION}>
                <Variant name='right-section'>
                  <PromoCodeBanner2 onWin={() => this.experimentRightSectionRef.current.win()} />
                </Variant>
              </Experiment>

              <CompetitionLeaderboardWrapper />
            </div>
          </div>
        </div>
      </PrivateTemplate>
    );
  };
}

function mapStateToProps(state) {
  return {
    openAddressForm: state.address.openAddressForm
  };
}

const mapDispatchToProps = {
  deliveryLockedToggle,
  fetchAddresses, addressFormToggle, saveAddress,
  submit
}

export default connect(mapStateToProps, mapDispatchToProps)(injectIntl(DocumentPage));
