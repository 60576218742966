import React from "react"
import { BancontactIcon, MasterCardIcon, VisaBlueIcon } from "../../Components/Icons";

const PaymentMethods = () => {

  return (
    <div className="d-flex">
      <BancontactIcon />
      <MasterCardIcon />
      <VisaBlueIcon />
    </div>
  );
}

export default PaymentMethods;