import React, { useEffect, useRef, useState } from "react";
import { getActiveNotification, hideOneTimeNotification } from "./temp_notification_config";
import { Button, Col, Container, Modal, ModalBody, ModalFooter, ModalHeader, Row } from "reactstrap";
import { FormattedMessage, useIntl } from "react-intl";
import ConfigurationService from "../Services/ConfigurationService";
import { logger } from '../shared/Logger';
import CustomSpinner from "../Components/Commons/Spinner";

const TempNotification = () => {

  const [loading, setLoading] = useState(true);
  const [notification, setNotification] = useState();
  const [open, setOpen] = useState(false);
  const intl = useIntl();
  const prevNotificationRef = useRef();
  const [customParameters, setCustomParameters] = useState({
    breakLine: (<div className='mt-1' />),
    breakLine2: (<div className='mt-2' />),
    breakLine3: (<div className='mt-3' />),
    b: (text) => (<strong>{text}</strong>),
    bigFont: (text) => (<span className="fs-5">{text}</span>)
  });

  useEffect(() => {
    
    if (!notification) {
      const current = getActiveNotification();
      if (current && prevNotificationRef.current?.key !== current.key) {
        setNotification(current);
        setOpen(true);
        prevNotificationRef.current = current;
      }
    } else if (notification.parameters) {
      setLoading(true);
      const keys = notification.parameters.map(param => param.key);

      ConfigurationService.getConfigurations(keys)
      .then(response => {
        const newParams = {};
        response.data.forEach(config => {
          newParams[notification.parameters.find(p => p.key === config.key).paramName] = config.value
        });
        setCustomParameters(prev => {
          return {...prev, ...newParams}
        });
      }).catch(error => logger.error("Imposible to load temporary notification parameters")).finally(() => setLoading(false));
    }
    
  }, [notification]);

  const toggle = () => {
    if (notification.toggle !== false) {
      handleClose();
    }
  }

  const handleClose = () => {
    if (notification?.one_time === true) {
      hideOneTimeNotification(notification);
    }
    setOpen(false);
    setNotification(null);
  }

  if (!notification) {
    return null;
  }

  return (
    <Modal isOpen={open} toggle={toggle} centered data-test="temp-notification" size={notification.size ? notification.size : null}>
      { notification.title ? <ModalHeader toggle={notification.toggle !== false ? toggle : null}>{intl.formatMessage({id: notification.title})}</ModalHeader> : null }
      <ModalBody>
        <Container fluid>
          <Row>
            { notification.picture ? <Col xs={{size: 8, offset: 2}} sm={{size: 4, offset: 0}}><img style={{width: "100%"}} src={notification.picture} /></Col> : null }
            <Col className="mt-3 mt-sm-0" xs="12" sm={notification.picture ? 8 : 12}>
              {loading && <CustomSpinner />}
              {!loading && <FormattedMessage id={notification.content} values={customParameters} /> }
            </Col>
          </Row>
        </Container>
      </ModalBody>
      {notification.toggle === false && (
        <ModalFooter>
          <Button color="secondary" onClick={handleClose}>
            <FormattedMessage id="btn.close" />
          </Button>
        </ModalFooter>
      )}
    </Modal>
  );

}

export default TempNotification;
