import React from 'react';
import { useSelector } from "react-redux";
import { FormattedMessage } from 'react-intl';
import InviteFriendByEmailForm from './InviteFriendsByEmailForm';
import InviteFriendsCopyLink from './InviteFriendsCopyLink';

import './InviteFriends.css';
import { PROMO_CODE_VALUE_FRIENDS } from '../../shared/utils';

const InviteFriends = (props) => {

  const user = useSelector(state => state.user.user);

  if (user === undefined || user === null) {
    return null;
  }

  return (
    <div>
      <div>
        <FormattedMessage id="inviteFriends.info" values={{
          value: PROMO_CODE_VALUE_FRIENDS,
          highlight: (text) => (
            <span className="font-weight-bold text-accent">{text}</span>
          )
        }}/>
      </div>

      <div className="mt-3">
        <InviteFriendsCopyLink />
      </div>

      <div className="mt-3">
        <InviteFriendByEmailForm />
      </div>

    </div>
  );
}

export default InviteFriends;
