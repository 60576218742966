import api from '../Api';
import { getToken } from '../shared/utils';

/**
 * User service to launch API calls to our servers.
 */
class UserService {

  /**
   * Try to delete user account.
   */
   static async delete(token) {

    return api.delete("/v1/user", {headers: {'X-AUTH-TOKEN': token}});
  }

  /**
   * Try to create new user with data parameter.
   */
  static async create(data) {

    return api.post("/v1/registration", data);
  }

  /**
   * Obtain logged user information.
   */
  static async fetch(token) {
    return api.get("/v1/user", {headers: {'X-AUTH-TOKEN': token}});
  }

  /**
   * Update logged user information.
   */
  static async update(token, data) {

    return api.post("/v1/user", data, {headers: {'X-AUTH-TOKEN': token}});
  }

  /**
   * Try to apply a promo code to logged user.
   */
  static async applyPromoCode(promoCode) {

    return api.post("/v1/user/promo", {code: promoCode}, {headers: {'X-AUTH-TOKEN': getToken()}});
  }

  /**
   * Invite people as friends of logged user.
   */
  static async inviteFriends(friends) {
    return api.post("/v1/user/invite", {friends: friends}, {headers: {'X-AUTH-TOKEN': getToken()}});
  }

  /**
   * Check if we need to ask for NPS to logged user.
   */
  static async askForNps(token) {

    return api.get("/v1/user/nps/ask", {headers: {'X-AUTH-TOKEN': token}});
  }

  static async nps(token, npsData) {

    return api.post("/v1/user/nps", npsData, {headers: {'X-AUTH-TOKEN': token}});
  }

  static async stats(token) {

    return api.get("/v1/user/stats", {headers: {'X-AUTH-TOKEN': token}});
  }

  /**
   * Request activation email for user related to email parameter.
   */
  static async activationEmail(email) {

      return api.post("/v1/user/activation", {email: email});
  }

  /**
   * Try to activate the related account of the key parameter.
   */
  static async activate(key) {

    return api.get("/v1/user/activate/" + key);
  }

  /**
   * Try to reset the password for account related to key parameter.
   */
  static async resetPassword(key) {

    return api.get("/v1/user/reset-password/" + key);
  }

  static async getConsentInfo(token) {

    return api.get("/v1/user/consent", {headers: {'X-AUTH-TOKEN': token}});
  }

  static async updateConsent(token, cookies) {

    const request = {
      "acceptCookies": cookies === true
    };

    return api.post("/v1/user/consent", request, {headers: {'X-AUTH-TOKEN': token}});
  }

  static async changePassword(token, data) {
    return api.post("/v1/user/change-password", data, {headers: {'X-AUTH-TOKEN': token}});
  }

  static async forgotPassword(email) {
    return api.post("/v1/forgot-password", {email: email});
  }

  static async getProfile() {

    let lang = localStorage.getItem("lang");

    if (!lang) {
      lang = "en";
    }

    return api.get(`/v1/user/profile?lang=${lang}`, {headers: {'X-AUTH-TOKEN': getToken()}});
  }

  static async getConfiguration() {
    return api.get("/v1/user/config", {headers: {'X-AUTH-TOKEN': getToken()}});
  }

}

export default UserService;
