import React, { useEffect, useState } from "react";

import { FormattedMessage, useIntl } from "react-intl";
import { PrivateTemplate } from "../../Templates";
import { Helmet } from "react-helmet";
import { Card, CardBody, Col, Container, Row } from "reactstrap";
import { Link, useParams } from "react-router-dom";
import PrintingService, { CS_TYPE, DELIVERY_TYPE } from "../Services/PrintingService";
import CustomSpinner from "../../Components/Commons/Spinner";
import ConfigurationService, { PRINT_ORDER_TIMEOUT } from "../../Services/ConfigurationService";
import { logger } from "../../shared/Logger";
import { ConfirmationIcon, WarningIcon } from "../../Components/Icons";

const SuccessPrintingPage = () => {

    const intl = useIntl();
    const [loading, setLoading] = useState(true);
    const { id } = useParams();
    const [data, setData] = useState();
    const [error, setError] = useState(false);
    const [messageKey, setMessageKey] = useState();
    const [messageParameters, setMessageParameters] = useState({});

    useEffect(() => {
        if (id) {

            PrintingService.get(id).then(response => {
                setData(response.data);
                setError(false);
            }).catch(error => {
                logger.error("Error while try to obtain printing order", JSON.stringify(error));

                setError(true);
                if (error.response.status === 404) {
                    setMessageKey("printing.msg.notFound");
                } else {
                    setMessageKey("errors.unexpected");
                }

            }).finally(() => setLoading(false))

        }
    }, [id]);

    useEffect(() => {

        if (data?.type === CS_TYPE) {
            setMessageKey("printing.success.copyShop");
            
            ConfigurationService.getConfiguration(PRINT_ORDER_TIMEOUT)
            .then(response => setMessageParameters({
                "days": response.data.value
            }))
            .catch(error => {
                logger.error("Error loading print order timeout", JSON.stringify(error));
                setMessageParameters({
                    "days": 7
                });
            });

        } else if (data?.type === DELIVERY_TYPE) {
            setMessageKey("printing.success.delivery");
            setMessageParameters({
                "estimatedDate": data.estimatedDate
            })
        }

    }, [data]);

    let content = null;

    if (data?.type === CS_TYPE) {
        content = (
            <div className="mt-3 d-flex justify-content-center">
                <div className="text-left">
                    <div className="font-weight-bold"><FormattedMessage id="printing.copyShopSelection.title" /></div>
                    <div>{data.name}</div>
                    <div>{data.address} - {data.city}</div>
                </div>
            </div>
        );
    }

    return (
        <PrivateTemplate>
            <Helmet>
                <title>Zerocopy - {intl.formatMessage({id: "printing.success.title"})}</title>
            </Helmet>
            <Container fluid>
                <Row className="mb-3">
                    <Col className="pl-0 text-center">
                        { !loading && !error && <SuccessMessage messageKey={messageKey} messageParameters={messageParameters}>{content}</SuccessMessage> }
                        { !loading && error && <ErrorMessage messageKey={messageKey} messageParameters={messageParameters} /> }
                        {loading && <CustomSpinner messageKey="Loading data" /> }
                    </Col>
                </Row>
                <Row>
                    <Col className="pl-0 text-center">
                        <Link to="/" className="btn btn-outline-secondary text-decoration-none mt-2">
                            <FormattedMessage id="printing.btnBack" />
                        </Link>
                    </Col>
                </Row>
            </Container>
        </PrivateTemplate>
    );

}

export default SuccessPrintingPage;

const SuccessMessage = ({messageKey, messageParameters, children}) => {

    return (
        <Card>
            <CardBody>
                <ConfirmationIcon />
                <div className="mt-3">
                    <FormattedMessage id={messageKey} values={messageParameters} />
                </div>
                {children}
            </CardBody>
        </Card>
    );
}

const ErrorMessage = ({messageKey, messageParameters}) => {

    return (
        <Card>
            <CardBody>
                <WarningIcon />
                <div className="mt-3 text-danger">
                    <FormattedMessage id={messageKey} values={messageParameters} />
                </div>
            </CardBody>
        </Card>
    );
}