import React, { useEffect, useState } from "react";
import { FormattedMessage } from "react-intl";
import { Link } from "react-router-dom";
import { Alert, Card, CardBody } from "reactstrap";
import ConfigurationService, { WEB_AD_CLICKS_FOR_REWARD } from "../../Services/ConfigurationService";
import { PROMO_CODE_VALUE_CLICKS, PROMO_CODE_VALUE_FRIENDS, PROMO_CODE_VALUE_SURVEYS } from "../../shared/utils";

const PromoCodeBanner = ({onWin}) => {

  const handleClick = () => {

    if (onWin) {
      onWin();
    }

  }

  return (
    <Alert color="accent" className="fs-5">
      <FormattedMessage id="printing.promoCodes.banner1.text" values={{
        link: (text) => (
          <Link to="/promo-codes" className="text-underline text-hover-bold" onClick={handleClick}>
            {text}
          </Link>
        )
      }} />
    </Alert>
  );
}

export default PromoCodeBanner;

export const PromoCodeBanner2 = ({onWin}) => {

  const [webAdClicks, setWebAdClicks] = useState(20);

  useEffect(() => {

    ConfigurationService.getConfiguration(WEB_AD_CLICKS_FOR_REWARD)
      .then(response => {
        setWebAdClicks(parseInt(response.data.value));
      }).catch(error => {
        logger.error("Error while obtain configuration of web ad clicks", JSON.stringify(error));
      });

  }, []);

  const handleClick = () => {

    if (onWin) {
      onWin();
    }

  }

  return (
    <Card className="zc-card">
      <CardBody>
        <h4>
          <FormattedMessage id="printing.promoCodes.banner2.title" />
        </h4>

        <ul>
          <li>
            <FormattedMessage id="printing.promoCodes.banner2.option.friends" values={{value: PROMO_CODE_VALUE_FRIENDS}} />
          </li>
          <li>
            <FormattedMessage id="printing.promoCodes.banner2.option.clicks" values={{value: PROMO_CODE_VALUE_CLICKS, clicks: webAdClicks  }} />
          </li>
          <li>
            <FormattedMessage id="printing.promoCodes.banner2.option.surveys" values={{value: PROMO_CODE_VALUE_SURVEYS}} />
          </li>
        </ul>
        <Link to="/promo-codes" className="btn btn-secondary btn-small btn-block" onClick={handleClick}>
          <FormattedMessage id="printing.promoCodes.banner2.button" />  
        </Link>
      </CardBody>
    </Card>
  );
}