import React, { Fragment, useState } from "react";
import { FormattedMessage } from "react-intl";
import { Button, Modal, ModalBody, ModalHeader } from "reactstrap";
import InviteFriends from "./InviteFriends";

import './InviteFriends.css'
import { PROMO_CODE_VALUE_FRIENDS } from "../../shared/utils";

const InviteFriendsButton = ({ size = "sm"}) => {

  const [open, setOpen] = useState(false);

  const toggle = (e) => {
      setOpen(!open);
      e.preventDefault();
  }

  return (
    <Fragment>
      <Button color="secondary" size={size} onClick={toggle} data-test="btn-invite-friends">
        <FormattedMessage id="inviteFriends.button" />
      </Button>

      <Modal isOpen={open} toggle={toggle} data-test="modal-invite-friends" id="inviteFriendsInfoModal">
        <ModalHeader toggle={toggle} tag="h4" className={'border-0 border-md-1sm- pb-0 pb-sm-0 pb-md-1'}>
          <FormattedMessage id="inviteFriends.title" values={{value: PROMO_CODE_VALUE_FRIENDS}} />
        </ModalHeader>
        <ModalBody className={'pt-0 pt-sm-0 pt-md-1'}>
          <InviteFriends />
        </ModalBody>
      </Modal>
    </Fragment>
  );
}

export default InviteFriendsButton;
