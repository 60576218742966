import React, { useEffect, useState } from "react";
import { PrivateTemplate } from "../../Templates";
import { Helmet } from "react-helmet";
import { Card, CardBody, CardTitle, Col, Container, Row } from "reactstrap";
import { FormattedMessage, useIntl } from "react-intl";

import Moment from "react-moment";

import './PromoCodePage.css';
import PrintingService from "../Services/PrintingService";
import { logger } from "../../shared/Logger";
import CustomSpinner from "../../Components/Commons/Spinner";
import { useDispatch, useSelector } from "react-redux";
import { success as successNotification, error as errorNotification } from 'react-notification-system-redux';
import ConfigurationService, { WEB_AD_CLICKS_FOR_REWARD } from "../../Services/ConfigurationService";
import { PROMO_CODE_VALUE_CLICKS, PROMO_CODE_VALUE_FRIENDS, PROMO_CODE_VALUE_SURVEYS } from "../../shared/utils";
import InviteFriends from "../../Components/InviteFriends/InviteFriends";
import { PartnerEmailButton } from "../../Components/Consent";

const PromoCodesPage = () => {

  const intl = useIntl();

  const [loading, setLoading] = useState(false);
  const [list, setList] = useState([]);

  useEffect(() => {

    setLoading(true);

    PrintingService.findPromoCodes()
      .then(response => {
        setList(response.data);
      }).catch(error => {
        setList([]);
        logger.error("Error while loading promo codes", JSON.stringify(error));
      }).finally(() => setLoading(false));

  }, []);

  return (
    <PrivateTemplate>
      <Helmet>
        <title>Zerocopy - {intl.formatMessage({id:"printing.promoCodes", defaultMessage: "Promo codes"})}</title>
      </Helmet>
      <Container fluid>
        <Row className="mb-3">
          <Col>
            <div className="border-bottom">
              <h2><FormattedMessage id="printing.promoCodes" /></h2>
            </div>
          </Col>
        </Row>
        <Row className="mb-3">
          <Col xs={12} xl={8}>
            <PromoCodeInviteFriendsExplanation />
          </Col>
        </Row>
        <Row className="mb-3">
          <Col xs={12} xl={8}>
            <PromoCodeWebAdClicksExplanation />
          </Col>
        </Row>
        <Row className="mb-3">
          <Col xs={12} xl={8}>
            <PromoCodeSurveysExplanation />
          </Col>
        </Row>
        <Row className="mb-3">
          <Col xs={12} xl={8}>
            <PromoCodePartnerEmailExplanation />
          </Col>
        </Row>
        <Row className="mt-3 mb-3">
          <Col>
            <div className="border-bottom">
              <h3><FormattedMessage id="printing.promoCodes.yourCodes" /></h3>
            </div>
          </Col>
        </Row>
        <Row>
          <Col>
            <div className="d-flex align-items-center flex-wrap" style={{gap: "10px"}}>
              { loading && <CustomSpinner messageKey="printing.promoCodes.yourCodes.loading" /> }
              { !loading && list.length === 0 && <span><FormattedMessage id="printing.promoCodes.yourCodes.empty" /></span>}
              { !loading && list.length > 0 && list.map((item, index) => <PromoCodeCard key={index} code={item.code} value={item.value} expiringDate={item.expiringDate} /> )}
            </div>
          </Col>
        </Row>
      </Container>
    </PrivateTemplate>
  );
}

export default PromoCodesPage;

const PromoCodeInviteFriendsExplanation = () => {

  return (
    <Card>
      <CardBody className="p-0">
        <div className="p-2">
          <CardTitle tag="h3">
            <FormattedMessage id="inviteFriends.title" values={{value: PROMO_CODE_VALUE_FRIENDS}} />
          </CardTitle>
          <InviteFriends />
        </div>
      </CardBody>
    </Card>
  );
}

const PromoCodeWebAdClicksExplanation = () => {

  const [webAdClicks, setWebAdClicks] = useState(20);

  useEffect(() => {

    ConfigurationService.getConfiguration(WEB_AD_CLICKS_FOR_REWARD)
      .then(response => {
        setWebAdClicks(parseInt(response.data.value));
      }).catch(error => {
        logger.error("Error while obtain configuration of web ad clicks", JSON.stringify(error));
      });

  }, []);

  return (
    <Card>
      <CardBody className="p-0">
        <div className="p-2">
          <CardTitle tag="h3">
            <FormattedMessage id="printing.promoCodes.webAdClicks" values={{
              value: PROMO_CODE_VALUE_CLICKS
            }}/>
          </CardTitle>
          <FormattedMessage id="printing.promoCodes.webAdClicks.description" values={{
            value: PROMO_CODE_VALUE_CLICKS,
            clicks: webAdClicks,
            highlight: (text) => (
              <span className="font-weight-bold text-accent">{text}</span>
            )
          }} />

          <div className="mt-3">
            <AdClickCounter />
          </div>

        </div>
        
      </CardBody>
    </Card>
  );
}

const PromoCodeSurveysExplanation = () => {

  return (
    <Card>
      <CardBody className="p-0">
        <div className="p-2">
          <CardTitle tag="h3">
            <FormattedMessage id="printing.promoCodes.surveys" values={{
              value: PROMO_CODE_VALUE_SURVEYS
            }} />
          </CardTitle>
          <FormattedMessage id="printing.promoCodes.surveys.description" values={{
            value: PROMO_CODE_VALUE_SURVEYS,
            highlight: (text) => (
              <span className="font-weight-bold text-accent">{text}</span>
            )
          }} />
        </div>
        
      </CardBody>
    </Card>
  );
}

const PromoCodePartnerEmailExplanation = () => {
  
  return (
    <Card>
      <CardBody className="p-0">
        <div className="p-2">
          <CardTitle tag="h3">
            <FormattedMessage id="printing.promoCodes.partnerEmails" />
          </CardTitle>
          <FormattedMessage id="printing.promoCodes.partnerEmails.description" values={{
            highlight: (text) => (
              <span className="font-weight-bold text-accent">{text}</span>
            )
          }} />
          <div className="mt-2">
            <PartnerEmailButton showInfo={false} />
          </div>
        </div>
        
      </CardBody>
    </Card>
  );
}

const PromoCodeCard = ({code, value, expiringDate}) => {

  const dispatcher = useDispatch();
  const intl = useIntl();

  const handleCopyCode = () => {
    navigator.clipboard.writeText(code);
    dispatcher(successNotification({
      message: intl.formatMessage({ id: "printing.promoCodes.yourCodes.copyValue.done"}),
      position: 'tc',
      autoDismiss: 3,
    }));
  }

  return (
    <div className="promo-code-card c-pointer text-center border p-3" onClick={handleCopyCode}>
        <div className="font-weight-bold text-primary fs-4">{code}</div>
        <div className="mt-2 font-weight-bold fs-5">
          <span><FormattedMessage id="printing.promoCodes.yourCodes.fields.value" />:</span>
          <span>{value}&nbsp;€</span>
        </div>
        <div className="text-accent mt-2 small">
          <span><FormattedMessage id="printing.promoCodes.yourCodes.fields.expiringDate" />: </span>
          <Moment parse="DD/MM/YYYY" format="DD MMM YYYY">{expiringDate}</Moment>
        </div>
        <div className="text-muted mt-3 small">
          <FormattedMessage id="printing.promoCodes.yourCodes.copyValue" />
        </div>
    </div>
  );
}


const AdClickCounter = () => {

  const adClickCounter = useSelector(state => state.user.adClickCounter);

  const { maxClicks, currentClicks } = adClickCounter;

  if (maxClicks === 0) {
      return null;
  }

  return (
    <span>
      <FormattedMessage id="printing.promoCodes.webAdClicks.counter" values={{max: maxClicks, current: Math.min(maxClicks, currentClicks)}} />
    </span>
  );
}