// Definition of each event name as constant to be used in any place on the application.

export const FAILED_UPLOAD = "Failed upload";
export const PAGE_VIEW = "Page view";
export const UNSUPPORTED_LANGUAGE = "Unsupported language";

// For A/B testing
export const EXPERIMENT_STARTED = "$experiment_started";
export const EXPERIMENT_WIN = "$experiment_win";

// Printing steps
export const PRINT_BUTTON_CLICK = "Print button click";
export const PRINT_SELECT_TYPE = "Print - Type selection";
export const PRINT_SELECT_LOCATION = "Print - Location selected";
export const PRINT_CHECKOUT_CLICK = "Print - Checkout click";
export const PRINT_PAYMENT_CREATED = "Print - Payment created";