import api from '../Api';
import { getToken } from '../shared/utils';

/**
 * Address service to launch API calls to our servers.
 */
class AddressService {

    /**
     * Obtain all addresses for logged user.
     * @param {string} token 
     */
    static async fetchAll(token) {
        return api.get("/v1/user/addresses", {headers: {'X-AUTH-TOKEN': token ? token : getToken()}});
    }

    /**
     * Obtain specific address for logged user.
     * @param {string} token 
     * @param {number} addressId 
     */
    static async fetch(token, addressId) {
        return api.get(`/v1/user/addresses/${addressId}`, {headers: {'X-AUTH-TOKEN': token}});
    }

    /**
     * Create or update address for logged user.
     * @param {string} token 
     * @param {object} address 
     */
    static async save(token, address) {

        let payload;

        if (address.id) {
            payload = api.post(`/v1/user/addresses/${address.id}`, address, {headers: {'X-AUTH-TOKEN': token ? token : getToken()}});
        } else {
            payload = api.put("/v1/user/addresses", address, {headers: {'X-AUTH-TOKEN': token ? token : getToken()}});
        }

        return payload;
    }

    /**
     * Delete address for logged user.
     * @param {string} token 
     * @param {number} addressId 
     */
    static async delete(token, addressId) {
        return api.delete(`/v1/user/addresses/${addressId}`, {headers: {'X-AUTH-TOKEN': token}});
    }
}

export default AddressService;