import React, { useCallback, useEffect, useState } from "react";
import { PrivateTemplate } from "../../Templates";
import { Helmet } from "react-helmet";
import { Badge, Button, Card, CardBody, Col, Container, Row, Spinner, Tooltip, Accordion, CardHeader, Collapse } from "reactstrap";
import { FormattedMessage, FormattedNumber, useIntl } from "react-intl";
import { Link } from "react-router-dom";
import PrintingService, { CS_TYPE, DELIVERY_TYPE } from "../Services/PrintingService";
import { logger } from "../../shared/Logger";
import CustomSpinner from "../../Components/Commons/Spinner";

import "./MyOrdersPage.css";
import { calendarStrings, groupBy } from "../../shared/utils";
import Moment from "react-moment";
import { PackageIcon } from "../../Components/Icons";

const DEFAULT_PAGE_SIZE = 5;

const MyOrdersPage = () => {

  const intl = useIntl();

  const [loading, setLoading] = useState(false);
  const [firstLoading, setFirstLoading] = useState(false);
  const [pageSize, setPageSize] = useState(DEFAULT_PAGE_SIZE);
  const [currentPage, setCurrentPage] = useState(0);
  const [list, setList] = useState([]);
  const [total, setTotal] = useState(0);

  const fetchData = useCallback(async (requestPage, requestPageSize, first = false) => {

    setLoading(true);

    PrintingService.find(requestPage, requestPageSize).then(response => {
      setList(prevData => [...prevData, ...response.data.data]);
      setTotal(response.data.total);
    }).catch(error => {
      logger.error("We cannot load printing orders", JSON.stringify(error));
    }).finally(() => {
      setLoading(false);
      if (first) {
        setFirstLoading(false);
      }
    });

  }, []) 

  useEffect(() => {

    setFirstLoading(true);
    fetchData(0, pageSize, true);

  }, [fetchData, pageSize]);

  const handlLoadMoreClick = (e) => {
    const newPage = currentPage + 1;
    setCurrentPage(newPage);
    fetchData(newPage, pageSize);
  }

  if (firstLoading) {
    return (
      <Loading />
    );
  }

  if (list?.length === 0) {
    return (
      <EmptyList />
    );
  }

  return (
    <PrivateTemplate>
      <Helmet>
          <title>Zerocopy - {intl.formatMessage({id:"myorders.title"})}</title>
      </Helmet>
      <Container fluid>
        <Row className="mb-3">
          <Col>
            <div className="border-bottom">
              <h2><FormattedMessage id="myorders.title"/></h2>
            </div>
          </Col>
        </Row>
        <Row>
          <Col>
            <OrderList list={list} />
          </Col>
        </Row>
        {list.length < total && <Row>
          <Col className="p-0 mt-2 text-center">
            <Button onClick={handlLoadMoreClick} disabled={loading}>
              {loading && <Spinner size="sm" className="mr-2" /> }
              <FormattedMessage id="myorders.btn.loadMore" />
            </Button>
          </Col>
        </Row> }
      </Container>
    </PrivateTemplate>
  );
}

export default MyOrdersPage;

const Loading = () => {
  const intl = useIntl();

  return (
    <PrivateTemplate>
      <Helmet>
        <title>Zerocopy - {intl.formatMessage({id:"myorders.title"})}</title>
      </Helmet>
      <Container fluid>
        <Row className="mb-3">
          <Col>
            <div className="border-bottom">
              <h2><FormattedMessage id="myorders.title"/></h2>
            </div>
          </Col>
        </Row>
        <Row>
          <Col className="text-center mt-3">
            <CustomSpinner messageKey="myorders.loading" />
          </Col>
        </Row>
      </Container>
    </PrivateTemplate>
  );
}

const EmptyList = () => {

  const intl = useIntl();

  return (
    <PrivateTemplate>
      <Helmet>
        <title>Zerocopy - {intl.formatMessage({id:"myorders.title"})}</title>
      </Helmet>
      <Container fluid>
        <Row>
          <Col className="text-center">
            <Card>
              <CardBody>
                  <PackageIcon className="fs-1" />
                  <div className="mt-3">
                    <FormattedMessage id="myorders.empty" />
                  </div>
              </CardBody>
            </Card>
          </Col>
        </Row>
        <Row className="text-center mt-3">
          <Col>
            <Link to="/" className="btn btn-outline-secondary text-decoration-none">
              <FormattedMessage id="printing.btnBack" />
            </Link>
          </Col>
        </Row>
      </Container>
    </PrivateTemplate>
  );
}

const OrderList = ({list}) => {

  let groupedData = {};

  if (Object.groupBy) {
    groupedData = Object.groupBy(list, ({created}) => created);
  } else {
    groupedData = groupBy(list, "created");
  }

  const keys = Object.keys(groupedData);

  return (
    keys.map((key, index) => 
      
      <Container key={index} fluid className="p-0">
        <Row className="mb-2">
          <Col>
            <Moment parse="DD/MM/YYYY" format="DD MMMM YYYY" calendar={calendarStrings}>{key}</Moment>
          </Col>
        </Row>
        {groupedData[key].map((item, index2) => <Row key={index2} ><Col className="mb-2"><OrderRow item={item} /></Col></Row>)}
      </Container>

    )
    
  );

}

const OrderRow = ({item}) => {

  const [copyTooltip, setCopyTooltip] = useState(false);

  const toggleCopyTooltip = () => {
    setCopyTooltip(prevState => !prevState);
  }

  const handleCopyIdentifierClick = () => {

    navigator.clipboard.writeText(item.externalId);
  }

  const pages = item.documents.reduce((acc, d) => acc + d.pages, 0);

  let fullAddress;

  if (item.address && item.address !== "") {
    fullAddress = item.address + " - " + item.postalCode + " " + item.city;
  }

  const isFree = !item.price || item.price === 0;

  return (
    <Card>
      <CardBody>
        
        <div className="d-flex flex-column flex-md-row justify-content-start justify-content-md-between mb-2">
          
          <div className="d-flex align-items-center mb-2 mb-md-0" style={{gap: "5px"}}>
            <span className="font-weight-bold"><FormattedMessage id="myorders.field.id" />:</span>
            <span>{item.externalId}</span>
            <span id={`copyOrderIdButton-${item.externalId}`} role="button" onClick={handleCopyIdentifierClick} className="copyOrderIdButton material-symbols-outlined c-pointer">content_paste</span>
            <Tooltip placement="left" target={`copyOrderIdButton-${item.externalId}`} toggle={toggleCopyTooltip} isOpen={copyTooltip}>
              <FormattedMessage id="myorders.field.id.copy" />
            </Tooltip>
          </div>

          <div className="d-flex-align-items-center">
            <StatusBadge item={item} />
          </div>

        </div>

        <div className="d-flex flex-column flex-md-row">

          <div className="d-flex align-items-center mb-2 flex-fill fontw-weight-bold" style={{gap: "5px"}}>
            <span className="material-symbols-outlined fs-6">euro</span>
            { !isFree && <span><FormattedNumber value={item.price / 100} minimumFractionDigits={2} maximumFractionDigits={2} /></span>}
            { isFree && <span className="text-uppercase"><FormattedMessage id="global.words.free" /></span> }
          </div>

          <div className="d-flex align-items-center justify-content-md-end mb-2" style={{gap: "5px"}}>
            <span className="material-symbols-outlined fs-6">location_on</span>
            { item.type === CS_TYPE && <><span><FormattedMessage id="myorders.field.location.copyShop" />:</span><span>{item.name}</span></> }
            { item.type === DELIVERY_TYPE && <><span><FormattedMessage id="myorders.field.location.delivery" />:</span><span>{fullAddress}</span></> }
          </div>

        </div>

        <div className="d-flex align-items-center" style={{gap: "5px"}}>
          <span className="material-symbols-outlined fs-5">description</span>
          <div className="d-flex align-items-center justify-content-between flex-fill" style={{gap: "5px"}}>
            <span className="font-weight-bold">
              <FormattedMessage id="global.words.documents" values={{docs: item.numDocs}}/>
            </span>
            <span className="text-nowrap ml-2"><FormattedMessage id="global.words.pages" values={{pages: pages}} /></span>
          </div>
        </div>

        { item.documents.length > 0 && 
          <>
            <div className="border-bottom my-3"></div>
            <div>
              <OrderDocs documents={item.documents} />
            </div>
          </>
        }

      </CardBody>
    </Card>
  );
}

const OrderDocs = ({documents}) => {

  const docs = documents.map((doc, index) => (
    <div key={index} className="d-flex align-items-center justify-content-between my-1">
      <span className="text-break text-wrap flex-fill">{doc.name}</span>
      <span className="text-nowrap ml-2"><FormattedMessage id="global.words.pages" values={{pages: doc.pages}} /></span>
    </div>
  ));

  return (
    <>
      {docs}
    </>
  );
}

const StatusBadge = ({item}) => {

  let badgeColor = "dark";
  let icon = "schedule";
  let textKey = "myorders.status." + item.status;
  switch (item?.status) {
    case "PAID":
    case "NO_PAYMENT":
      badgeColor = "secondary";
      icon = "print";
      textKey = textKey + "." + (item.type === DELIVERY_TYPE ? "delivery": "copyShop");
      break;
    case "CANCELLED":
      icon = "cancel";
      badgeColor = "danger";
      break;
    case "PRINTED":
      icon = "verified";
      badgeColor = "primary";
      break;
    case "REFUND":
      icon = "warning";
      badgeColor = "warning";
      break;
  }

  return (
    <Badge color={badgeColor}>
      <div className="d-flex align-items-center" style={{gap: "5px"}}>
        <span className="material-symbols-outlined fs-6">{icon}</span>
        <span><FormattedMessage id={textKey} /></span>
      </div>
    </Badge>
  );
}