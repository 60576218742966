import * as actionTypes from './types';

export const fetchUser = () => {
  return {
    type: actionTypes.USER_FETCH
  }
};

export const fetchUserSuccess = (user) => {
  return {
    type: actionTypes.USER_FETCH_SUCCESS,
    user: user
  }
};

export const fetchUserError = (error) => {
  return {
    type: actionTypes.USER_FETCH_ERROR,
    error: error
  }
};

export const userUpdate = (username, email, fullname, intl) => {
  return {
    type: actionTypes.USER_UPDATE,
    username: username,
    email: email,
    fullname: fullname,
    intl: intl
  };
};

export const userUpdateSuccess = () => {
  return {
    type: actionTypes.USER_UPDATE_SUCCESS
  };
};

export const userUpdateError = (error) => {
  return {
    type: actionTypes.USER_UPDATE_ERROR,
    error: error
  };
};

export const userForgotPasswordToggle = () => {
  return {
      type: actionTypes.USER_FORGOT_PASSWORD_TOGGLE
  };
};

export const userForgotPassword = (email, intl) => {
  return {
      type: actionTypes.USER_FORGOT_PASSWORD,
      email: email,
      intl: intl
  };
};

export const userForgotPasswordSuccess = () => {
  return {
      type: actionTypes.USER_FORGOT_PASSWORD_SUCCESS
  };
};

export const userForgotPasswordError = (error) => {
  return {
      type: actionTypes.USER_FORGOT_PASSWORD_ERROR,
      error: error
  };
};

export const userForgotPasswordClear = () => {
  return {
      type: actionTypes.USER_FORGOT_PASSWORD_CLEAR
  };
};

export const userCreateAccountToggle = () => {
  return {
      type: actionTypes.USER_CREATE_ACCOUNT_TOGGLE
  };
};

export const userCreateAccount = (email, password, passwordRepeat, intl, notify) => {
  return {
      type: actionTypes.USER_CREATE_ACCOUNT,
      email: email,
      password: password,
      passwordRepeat: passwordRepeat,
      intl: intl,
      notify: notify,  
  };
};

export const userCreateAccountSuccess = () => {
  return {
      type: actionTypes.USER_CREATE_ACCOUNT_SUCCESS
  };
};

export const userCreateAccountError = (error) => {
  return {
      type: actionTypes.USER_CREATE_ACCOUNT_ERROR,
      error: error
  };
};

export const userCreateAccountClear = () => {
  return {
      type: actionTypes.USER_CREATE_ACCOUNT_CLEAR
  };
};

export const userActivate = (activationKey, intl) => {
  return {
    type: actionTypes.USER_ACTIVATE,
    activationKey: activationKey,
    intl: intl
  }
}

export const userResetPassword = (resetPasswordKey, intl) => {
  return {
    type: actionTypes.USER_RESET_PASSWORD,
    resetPasswordKey: resetPasswordKey,
    intl: intl
  }
}

export const npsToggle = () => {
  return {
    type: actionTypes.USER_NPS_TOGGLE
  }
}

export const npsUpdateRating = (newRating) => {
  return {
    type: actionTypes.USER_NPS_RATING,
    rating: newRating
  }
}

export const npsSubmit = (model, rating, intl) => {
  return {
    type: actionTypes.USER_NPS_SUBMIT,
    rating: rating,
    model: model,
    intl: intl
  }
}

export const npsSubmitted = () => {
  return {
    type: actionTypes.USER_NPS_SUBMITTED
  }
}

export const npsAsk = () => {
  return {
    type: actionTypes.USER_NPS_ASK
  };
}

export const npsNextStep = () => {
  return {
    type: actionTypes.USER_NPS_NEXT
  }
}

export const userStatsFetch = () => {
  return {
    type: actionTypes.USER_STATS
  }
}

export const userStatsSuccess = (data) => {
  return {
    type: actionTypes.USER_STATS_SUCCESS,
    data: data
  }
}

export const userStatsError = (error) => {
  return {
    type: actionTypes.USER_STATS_ERROR,
    error: error
  }
}

export const userGetAdClickCounterFetch = (intl) => {
  return {
    type: actionTypes.USER_GET_AD_CLICK_COUNTER,
    intl: intl
  }
}

export const userGetAdClickCounterSuccess = (data) => {
  return {
    type: actionTypes.USER_GET_AD_CLICK_COUNTER_SUCCESS,
    data: data
  }
}

export const userGetAdClickCounterError = (error) => {
  return {
    type: actionTypes.USER_GET_AD_CLICK_COUNTER_ERROR,
    error: error
  }
}
