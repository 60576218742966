import React from 'react';
import { useIntl } from 'react-intl';
import { Helmet } from "react-helmet";

import { PrivateTemplate } from '../../../Templates/PrivateTemplate.js';
import Locations from '../Locations';

import "./CopyShopPage.css";

const CopyshopPage = (props) => {

  const intl = useIntl();

  let title = intl.formatMessage({id: "copyshop.title"});

  return (
    <PrivateTemplate>
      <Helmet>
          <title>{title}</title>
      </Helmet>


      <div className="container-fluid page-in" > 

        <div className="row">
          <div className="col-12">
            <Locations title={title}/>
          </div>
        </div>
      </div>
    </PrivateTemplate>
  );
}

export default CopyshopPage;

