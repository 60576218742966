import React from "react"
import { getUniqueLink } from "../../shared/utils";
import { useDispatch, useSelector } from "react-redux";
import { FormattedMessage, useIntl } from "react-intl";
import { success as successNotification } from 'react-notification-system-redux';

const InviteFriendsCopyLink = ({title, sm = false}) => {

  const intl = useIntl();
  const user = useSelector(state => state.user.user);

  const dispatcher = useDispatch();

  if (!user) {
    return null;
  }

  const shareLink = getUniqueLink(user.id);

  const handleCopyLink = () => {

    navigator.clipboard.writeText(shareLink);

    dispatcher(successNotification({
        message: intl.formatMessage({ id: "inviteFriends.shareLink.message.success"}),
        position: 'tc',
        autoDismiss: 20,
    }));
  
  }

  return (
    <div className="invite-friends-link">
      <div>
        <label className="font-weight-bold mb-1 mb-md-2">
          {title ? title : <FormattedMessage id="inviteFriends.shareLink" /> }
        </label>
      </div>
      <div className="d-flex flex-column flex-sm-row">
          <div className="flex-fill">
            <input type="text" value={shareLink} className={`form-control${sm === true ? " form-control-sm":""}`} disabled />
          </div>
          <div className="ml-0 ml-sm-2 mt-2 mt-sm-0 text-center">
            <button className="btn btn-secondary" onClick={handleCopyLink} data-test="btn-invite-friends-copy-link">
              <i className="far fa-copy"></i>
              &nbsp;<FormattedMessage id="inviteFriends.shareLink.button.copy" />
            </button>
          </div>
      </div>
    </div>
  );
}

export default InviteFriendsCopyLink;
